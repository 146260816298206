<script setup lang="ts">
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useUser } from '/~/composables/user'

const { user } = useUser()

withDefaults(
  defineProps<{
    actionButton: Record<string, any>
    showGreetings: boolean
    showAction: boolean
  }>(),
  {
    showGreetings: true,
    showAction: true,
    actionButton: () => ({}),
  }
)
</script>

<template>
  <div class="flex items-center">
    <p
      v-show="showGreetings"
      class="text-4xl font-bold leading-10 tracking-tight text-eonx-neutral-800"
      data-testid="greetings"
    >
      Hi, {{ user.firstName }}
    </p>
    <base-action
      v-show="showAction"
      v-bind="actionButton"
      origin="banner"
      appearance="link"
      class="ml-auto"
    >
      <base-button size="sm">
        {{ actionButton.label }}
      </base-button>
    </base-action>
  </div>
</template>
